var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row mb-2" }, [
        _c(
          "div",
          { staticClass: "col-sm" },
          [
            _c(
              "CLink",
              {
                staticClass: "d-block mb-3",
                attrs: { color: "info" },
                on: { click: _vm.copyFromOpeningTimeToDeliveryTime },
              },
              [
                _vm.copySucceeded.openingTime
                  ? _c(
                      "span",
                      [
                        _c("CIcon", { attrs: { name: "cil-check-alt" } }),
                        _vm._v(" Copied!"),
                      ],
                      1
                    )
                  : _c(
                      "span",
                      [
                        _c("CIcon", {
                          staticClass: "mr-1",
                          attrs: { name: "cil-clone" },
                        }),
                        _vm._v("Copy From Opening Time"),
                      ],
                      1
                    ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-sm-4" },
          [
            _c(
              "CButton",
              {
                staticClass: "px-0",
                attrs: { color: "link" },
                on: { click: _vm.deliveryTimesCopyTo },
              },
              [
                _vm.copySucceeded.deliveryTimesCopyTo
                  ? _c(
                      "span",
                      [
                        _c("CIcon", {
                          staticClass: "mr-1",
                          attrs: { name: "cil-check-alt" },
                        }),
                        _vm._v("Applied!"),
                      ],
                      1
                    )
                  : _c(
                      "span",
                      [
                        _c("CIcon", {
                          staticClass: "mr-1",
                          attrs: { name: "cil-copy" },
                        }),
                        _vm._v("Apply Monday to all days"),
                      ],
                      1
                    ),
              ]
            ),
          ],
          1
        ),
        _vm._m(0),
        _vm._m(1),
      ]),
      _c("restaurant-times", {
        attrs: { day: "Monday", time: _vm.$v.form.deliver_monday.$model },
        on: {
          isValid: (value) => (_vm.isValidDeliveryTime.mo = value),
          "update:time": function ($event) {
            return _vm.$set(_vm.$v.form.deliver_monday, "$model", $event)
          },
        },
      }),
      _c("restaurant-times", {
        attrs: { day: "Tuesday", time: _vm.$v.form.deliver_tuesday.$model },
        on: {
          isValid: (value) => (_vm.isValidDeliveryTime.tu = value),
          "update:time": function ($event) {
            return _vm.$set(_vm.$v.form.deliver_tuesday, "$model", $event)
          },
        },
      }),
      _c("restaurant-times", {
        attrs: { day: "Wednesday", time: _vm.$v.form.deliver_wednesday.$model },
        on: {
          isValid: (value) => (_vm.isValidDeliveryTime.we = value),
          "update:time": function ($event) {
            return _vm.$set(_vm.$v.form.deliver_wednesday, "$model", $event)
          },
        },
      }),
      _c("restaurant-times", {
        attrs: { day: "Thursday", time: _vm.$v.form.deliver_thursday.$model },
        on: {
          isValid: (value) => (_vm.isValidDeliveryTime.th = value),
          "update:time": function ($event) {
            return _vm.$set(_vm.$v.form.deliver_thursday, "$model", $event)
          },
        },
      }),
      _c("restaurant-times", {
        attrs: { day: "Friday", time: _vm.$v.form.deliver_friday.$model },
        on: {
          isValid: (value) => (_vm.isValidDeliveryTime.fr = value),
          "update:time": function ($event) {
            return _vm.$set(_vm.$v.form.deliver_friday, "$model", $event)
          },
        },
      }),
      _c("restaurant-times", {
        attrs: { day: "Saturday", time: _vm.$v.form.deliver_saturday.$model },
        on: {
          isValid: (value) => (_vm.isValidDeliveryTime.sa = value),
          "update:time": function ($event) {
            return _vm.$set(_vm.$v.form.deliver_saturday, "$model", $event)
          },
        },
      }),
      _c("restaurant-times", {
        attrs: { day: "Sunday", time: _vm.$v.form.deliver_sunday.$model },
        on: {
          isValid: (value) => (_vm.isValidDeliveryTime.su = value),
          "update:time": function ($event) {
            return _vm.$set(_vm.$v.form.deliver_sunday, "$model", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-3 d-none d-sm-block px-sm-0" }, [
      _c("h5", { staticClass: "mb-0" }, [_vm._v("Open from")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-3 d-none d-sm-block" }, [
      _c("h5", { staticClass: "mb-0" }, [_vm._v("Open till")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }